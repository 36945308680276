<template>
  <div class="nav-btn">
    <div
      class="item-btn"
      v-for="(item, index) in btnList"
      :key="index"
      @click="toList(index, item.name)"
    >
      <img :src="item.img" alt="" /> {{ item.name }}
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "navBtn",
  data() {
    return {
      btnList: [
        {
          name: "人大概况",
          img: require("@/assets/images/1.png"),
          path: "/survey",
        },
        {
          name: "依法履职",
          img: require("@/assets/images/2.png"),
          path: "/accordingLaw",
        },
        {
          name: "工作动态",
          img: require("@/assets/images/3.png"),
          path: "/partyLeads",
        },
        {
          name: "民意直通车",
          img: require("@/assets/images/4.png"),
          path: "/Suggest",
        },
      ],
    };
  },
  created() {},
  methods: {
    toList(index, name) {
      if (name == "依法履职") {
        let is_rd = this.$store.state.user.userInfo.is_rd;
        if (is_rd == 0) {
          Toast.fail("非人大代表");
        } else {
          this.$router.push(this.btnList[index].path);
        }
      } else {
        this.$router.push(this.btnList[index].path);
      }
    },
  },
};
</script>

<style scoped lang="less">
.nav-btn {
  width: 6.9rem;
  height: 2.1rem;
  padding: 0 0.39rem;
  margin-top: 0.16rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 0.08rem;
  .item-btn {
    margin: auto 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #424242;
    font-size: 0.26rem;
    img {
      width: 0.85rem;
      height: 0.85rem;
      margin-bottom: 0.16rem;
    }
  }
}
</style>