<template>
  <div class="home">
    <div class="bg"></div>
    <!-- 轮播图 -->
    <div class="img-con">
      <swiper v-if="bannerList && bannerList.length" :list="bannerList" />
    </div>
    <Navbtn></Navbtn>
    <!-- 滚动通知栏 -->
    <van-notice-bar color="#36ABFF" background="#ffffff" :scrollable="false">
      <img class="notice-img" src="@/assets/images/home/xx.png" />
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item
          v-for="(item, index) in noticeList"
          :key="index"
          @click="toArticleDetail(item.id)"
        >
          {{ item.title }}
        </van-swipe-item>
      </van-swipe>
    </van-notice-bar>
    <div class="imgs-con">
      <img
        src="@/assets/images/home/left.png"
        alt=""
        @click="toVillageDynamic"
      />
      <img
        src="@/assets/images/home/right.png"
        alt=""
        @click="toQuestionnaire"
      />
      <img
        src="@/assets/images/home/middle.png"
        alt=""
        @click="toColorfulLife"
      />
      
    </div>

    <div class="text-con" @click="init">
      <!-- 最新动态 -->
      <div class="RowName">
        <div class="left1"></div>
        最新公告
      </div>
      <div class="right" @click="toMore">更多 ></div>
    </div>

    <div class="list-con">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="list"
        @click="toArticleDetail(item.id)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="imgs" v-if="item.images.length > 0">
          <img
            :src="$IMG_PATH + pitem"
            alt=""
            v-for="(pitem, pindex) in item.images"
            :key="pindex"
          />
        </div>
        <div class="foot">
          <div>
            <span class="iconfont"></span>
            {{ item.add_time }}
          </div>
          <div class="likes">
            <span class="iconfont icon-check-line"></span>
            {{ item.click_count }}
          </div>
        </div>
      </div>
    </div>
    <tabber></tabber>
  </div>
</template>

<script>
import tabber from "@/components/tabber/tabber.vue";
import Swiper from "@/components/Swiper";
import Navbtn from "./components/navBtn/navBtn";
import LoadList from "@/components/SearchLoadList";
import { getBanner, getInform } from "./api";
export default {
  name: "home",
  components: {
    tabber,
    Swiper,
    Navbtn,
    LoadList,
  },
  data() {
    return {
      bannerList: [],
      noticeList: [],
      list:[],
      interFace: {
        api: getInform,
        params: {
          cid: 14,
        },
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    goToNav(path) {
      this.$router.push(path);
    },
    toArticleDetail(id) {
      this.$router.push({
        name: "articleDetail",
        params: {
          id: id,
        },
      });
    },
    init() {
      let params = {
        position: 20001,
      };
      getBanner(params).then((res) => {
        this.bannerList = res.data.map((e) => {
          return this.$IMG_PATH + e.image;
        });
      });
      this.getInform();
    },
    getInform() {
      let params = {
        cid: 14,
      };
      getInform(params).then((res) => {
        this.noticeList = res.data.items;
        this.list = res.data.items.splice(0, 5);
      });
    },
    toMore() {
      this.$router.push("/partyLeads");
    },

    toVillageDynamic() {
      this.$router.push({
        name: "voting",
      });
    },
    toColorfulLife() {
      this.$router.push({
        name: "proposal",
      });
    },
    toQuestionnaire() {
      this.$router.push({
        name: "questionnaireList",
      });
    },
  },
};
</script>

<style scoped lang="less">
.home {
  width: 100%;
  padding: 0.24rem 0.3rem;
  overflow: hidden;
  background-color: #fff;
  .bg {
    width: 100%;
    height: 3.4rem;
    position: absolute;
    left: 0;
    top: 0;
    background: url("../../assets/images/home/home_bg.png") no-repeat center;
    background-size: 100% 100%;
  }
  .img-con {
    width: 100%;
    height: 3.4rem;
    overflow: hidden;
    /deep/ .van-swipe {
      width: 100%;
      height: 3.4rem;
      overflow: hidden;
    }
    /deep/ img {
      width: 100%;
      height: 3.4rem;
      display: block;
      border-radius: 0.08rem;
    }
  }
  .notice-img {
    vertical-align: middle;
  }
  .notice-swipe {
    height: 0.4rem;
    // line-height: 0.4rem;
    width: 80%;
    float: right;
    color: #999999;
  }
  /deep/ .van-icon {
    color: #ff0000 !important;
  }
  .imgs-con {
    width: 100%;
    height: 2.6rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0.16rem;
    img {
      width: 2.18rem;
      height: 2.6rem;
    }
  }
  .img-con-5 {
    width: 6.9rem;
    height: 1.5rem;
    margin: 0.3rem auto;
  }
  .text-con {
    display: flex;
    justify-content: space-between;
    color: #333333;
    font-size: 0.36rem;
    margin: 0.29rem 0 0 0;
    .RowName {
      .left1 {
        float: left;
        height: 25px;
        width: 5px;
        background: #3b93ff;
        margin-right: 0.15rem;
      }
    }
    .right {
      font-size: 0.28rem;
      color: #999;
    }
  }
  .list-con {
    padding-bottom: 0.84rem;
    .list {
      width: 6.9rem;
      // height: 4rem;
      margin: 0 auto;
      background: #fff;
      border-radius: 8px;
      font-size: 0.32rem;
      color: #666666;
      margin-top: 0.2rem;
      padding: 0.25rem 0.25rem 0.25rem;
      border-bottom: 1px solid #f7f7f7;
      // display: flex;
      .title {
        font-size: .28rem;
        line-height: 0.44rem;
      }
      .imgs {
        display: flex;
        overflow-x: auto;
        justify-content: space-between;
        margin-top: 0.3rem;
        img {
          width: 2.06rem;
          height: 1.52rem;
          margin-right: 0.14rem;
        }
      }
      .foot {
        margin-top: 0.3rem;
        font-size: 0.26rem;
        color: #8b8b8b;
        display: flex;
        align-content: flex-end;
        div {
          vertical-align: middle;
        }
        .likes {
          margin-left: 3.2rem;
        }
        .looks {
          margin-left: 0.6rem;
        }
      }
    }
  }
}
</style>