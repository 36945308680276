<template>
  <div class="navigation">
    <van-tabbar route>
      <van-tabbar-item
        v-for="(item, index) in navList"
        :key="index"
        replace
        :to="item.link"
      >
        <span>{{ item.name }}</span>
        <template v-slot:icon="props">
          <img :src="props.active ? item.active : item.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "娄庄人大",
          link: "/",
          active: require("../../assets/images/tabber/selectHome.png"),
          inactive: require("../../assets/images/tabber/home.png"),
        },
        {
          name: "个人中心",
          link: "/Mine",
          active: require("../../assets/images/tabber/selectMy.png"),
          inactive: require("../../assets/images/tabber/my.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.navigation {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
}
</style>